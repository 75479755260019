/* You can add global styles to this file, and also import other style files */

$body-bg:       #000;
$body-color:    #E0ECE4;

$primary:       #66BFBF;
$secondary:     #FF6B6B;
$tertiary:      #056674;

$theme-colors: (
    "tertiary": $tertiary
);

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
